@import "tailwindcss";
@import "tailwindcss-primeui";

:root {
    --primary-color: #3260a8;
    --secondary-color: #F5DC31;


    .mat-mdc-snack-bar-container.snackbar-warning {
        --mat-snack-bar-button-color: #fff;
        --mdc-snackbar-container-color: #c40202;
        --mdc-snackbar-supporting-text-color: #fff;
    }

    .snackbar-success {
        --mat-snack-bar-button-color: #fff;
        --mdc-snackbar-container-color: #008000;
        --mdc-snackbar-supporting-text-color: #fff;

    }

    .mat-mdc-snack-bar-container {
        margin-top: 20% !important;
        margin-right: 100px;
        align-items: center;
    }

    .p-tag {
        font-weight: 500
    }

    .p-speeddial-action {
        width: 2rem;
        height: 2rem;
    }

    .p-datatable-row-selected .p-button-text {
        color: var(--p-datatable-row-selected-color);
    }
}

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/keyboard-css/dist/css/main.css";
@import "../node_modules/primeicons/primeicons.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Inter", "Helvetica Neue", sans-serif;
}